<template>
    <div class="search">
      <div class="sea_head">
        <form action="/">
          <van-search
            v-model="value"
            show-action
            placeholder="请输入搜索关键词"
            @search="onSearch"
            @cancel="onCancel"
          />
        </form>
      </div>
      <div class="search_list">
        <div class="search_list_head" v-show="historyList.length">
          <span>历史记录</span>
          <span  @click="historyList=[]">清空</span>
        </div>
        <div class="search_list_item" v-for="item in historyList">
          {{item.keywords}}
        </div>

      </div>
      <div class="td_brd" v-show="historyList.length"> </div>
      <div class="search_list_head">
        <span style="margin-top: 12px">热门搜索</span>

      </div>
      <div class="search_hot">
        <div class="search_hot_item" v-for="item in hotList" @click="onSearch(item.keywords)">
          <span>{{item.keywords}}</span>
          <img src="./img/huore.png" alt="">
        </div>

      </div>
    </div>
</template>

<script src="./search.js">

</script>

<style scoped src="./search.css">

</style>
